@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .theading {
    @apply px-8 py-2 lg:px-6 sm:px-4 text-sm sm:text-sm ;
  }

  .heading {
    @apply text-xl sm:text-2xl lg:text-header text-[#202124]  font-bold mb-3 md:mb-6;
  }
  .heading-2 {
    @apply text-black font-bold md:text-4xl lg:text-5xl;
  }
  .heading-para {
    @apply text-sm sm:text-base lg:text-xl 2xl:text-2xl  font-normal;
  }
  .h-heading-para {
    @apply text-sm sm:text-base lg:text-2xl;
  }
  .sub-heading {
    @apply text-sm md:text-base lg:text-xl;
  }

  .menuBtn {
    /* @apply flex items-center gap-4 w-full border border-slate-100 px-4 py-2 text-left bg-white duration-200 hover:text-gray-900 text-gray-700 hover:bg-gray-100  cursor-pointer ; */
    @apply flex items-center gap-4 w-full border border-slate-100 px-4 py-2 text-left bg-white duration-200 hover:text-gray-900 text-gray-700 hover:bg-gray-100  cursor-pointer ;
  }

  .tabBtn {
    @apply transition duration-300 ease-in border border-[#9F9F9F] text-[#9B9B9B] text-sm font-medium py-3 rounded-[5px]
  }
  .activeTabBtn {
    @apply border-darkGreen text-darkGreen
  }

}

*,
*:after,
*:before {
  box-sizing: border-box;
}
:root {
  --primary-color: #d8f2f2;
  --green: #61a641;
  --red: #8bd9d9;
  --text-black: #333333;
  --white: #ffff;
  --white-blue: #d8f2f2;
}

.present-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #005f6a;
  overflow: scroll;
  padding: 20px;
}

html {
  /* font-size: calc(100vw / 1440 * 10); */
  overflow-x: hidden;

  /* @media (max-width: 600px) {
    font-size: calc(100vw / 375 * 10);
  } */
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #dfedd9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  text-decoration: none;
}


button.ant-btn-primary.ant-btn-sm {
  background-color: #005F6A !important;
}

